import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import Glide from "@glidejs/glide"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../graphql/fragments"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { Cover } from "../../components/cover/cover"
import { CoverMain } from "../../components/cover-main/cover-main"
import { CoverFeatured } from "../../components/cover-featured/cover-featured"
import { Card } from "../../components/card/card"
import { HeadingBasic } from "../../components/heading/heading"
import {
  WpGroup,
  WpIconlist,
  Stats,
  Carousel,
} from "../../components/utils/render-functions"
// import SustainabilityDetail from "../../templates/sustainability-detail"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const Environment = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "img_development-main.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "sustainability/environment") {
          id
          title
          slug
          blocks {
            name
            ...AcfGalleryBlock
            ...CoreGroupBlock
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreCoverBlock
            ...CoreShortcodeBlock
            ...CoreEmbedYoutube
          }
          childPages {
            nodes {
              title
              slug
              excerpt
              featuredImage {
                sourceUrl
                title
                caption
              }
              translation(language: ID) {
                title
                slug
                excerpt
                featuredImage {
                  sourceUrl
                  title
                  caption
                }
                seo {
                  title
                  twitterDescription
                  metaDesc
                  opengraphDescription
                }
              }
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
          translation(language: ID) {
            title
            blocks {
              name
              ...AcfGalleryBlock
              ...CoreGroupBlock
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreCoverBlock
              ...CoreShortcodeBlock
              ...CoreEmbedYoutube
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    const gallery = document.querySelectorAll("#gallery")

    gallery.forEach(item => {
      const slider = new Glide(item, {
        perView: 1,
        gap: 10,
        startAt: 0,
        breakpoints: {
          992: {
            perView: 1,
          },
        },
      })

      slider.mount()
    })
  }, [])

  const wordPress = data?.wordPress?.pageBy?.blocks
  const seo = data.wordPress.pageBy.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  return (
    <Layout location={location} currentLocation={location.uri} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data?.imgVideoCover?.childImageSharp?.fluid
        }
        twitterImg={
          cover
            ? cover?.attributes?.url
            : data?.imgVideoCover?.childImageSharp?.fluid
        }
        img={
          cover
            ? cover?.attributes?.url
            : data?.imgVideoCover?.childImageSharp?.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={data?.wordPress?.pageBy?.title}
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt="Social"
          className="cover-md"
        >
          <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress?.map((item, i) => {
        switch (item.name) {
          case "core/heading":
            return (
              <div key={`heading-${i}`} className="container">
                <div className={i === 0 ? "py-main" : ""}>
                  <HeadingBasic h2={item.attributes.content} />
                </div>
              </div>
            )
          case "core-embed/youtube":
            return (
              <div className="py-main">
                <Cover
                  variant="basic"
                  theme="dark"
                  img={null}
                  imgHeight="h-400px h-md-500px w-100"
                  // imgOverlay="40"
                  className="cover cover-full-top"
                  type="video-popup"
                  videoSrc={item.attributes && item.attributes.url}
                  type={{ name: "core-embed/youtube" }}
                  audio={false}
                />
              </div>
            )
          case "core/buttons":
            if (
              item.innerBlocks[0].attributes.url &&
              item.innerBlocks[0].attributes.url.split(".").length > 1
            ) {
              return (
                <div key={`button=${i}`} className="container">
                  <div className={i === 0 ? "py-main" : "pb-main"}>
                    <HeadingBasic>
                      <a
                        href={item.innerBlocks[0].attributes.url}
                        target="_blank"
                        className="btn btn-link fx-underline"
                        rel="noopener noreferrer"
                        key={`${item.innerBlocks[0].attributes.url}-${i}`}
                      >
                        {item.innerBlocks[0].attributes.text} &nbsp;
                        <i className="far fa-external-link"></i>
                      </a>
                    </HeadingBasic>
                  </div>
                </div>
              )
            } else {
              return (
                <div key={`button-${i}`} className="container">
                  <Link
                    key={`${item.innerBlocks[0].attributes.url}-${i}`}
                    to={item.innerBlocks[0].attributes.url}
                    className="btn btn-link fx-underline"
                  >
                    {item.innerBlocks[0].attributes.text}
                  </Link>
                </div>
              )
            }
          case "core/paragraph":
            return (
              <div key={`headingbasic-${i}`} className="py-main">
                <div className="container container-md">
                  <HeadingBasic text={[item.attributes.content]} />
                </div>
              </div>
            )
          case "core/group":
            return (
              <div key={`${item.name}-${i}`} className="py-main">
                <WpGroup data={item} />
              </div>
            )
          case "acf/iconlist":
            return (
              <div
                key={`iconlist-${i}`}
                className={i === 0 ? "container py-main" : "container pb-main"}
              >
                <WpIconlist data={item} />
              </div>
            )
          case "acf/labelvalues":
            return (
              <div key={`${item.name}-${i}`} className="pb-main">
                <Stats data={item} />
              </div>
            )
          case "core/cover":
            if (i > 0) {
              return (
                <div key={`featured-${i}`} className="pb-main">
                  <CoverFeatured
                    img={
                      item.attributes.url || data.featured.childImageSharp.fluid
                    }
                    title={
                      item.innerBlocks[0] !== undefined &&
                      item.innerBlocks[0].attributes.content
                    }
                    text={
                      item.innerBlocks[1] !== undefined &&
                      item.innerBlocks[1].attributes.content
                    }
                    btnText={
                      item.innerBlocks[2] !== undefined &&
                      item.innerBlocks[2].innerBlocks[0].attributes.text
                    }
                    btnLink={
                      item.innerBlocks[2] !== undefined &&
                      item.innerBlocks[2].innerBlocks[0].attributes.url
                    }
                  />
                </div>
              )
            } else {
              return <div key={`featured-${i}`}></div>
            }
          case "acf/acfgallery":
            return (
              <div key={`gallery-${i}`}>
                <Carousel data={item} />
              </div>
            )
          case "core/shortcode":
            if (item.attributes.text === "section-subpage") {
              return (
                <section key={`section-subpage-${i}`} className="pb-main">
                  <div className="container">
                    {data.wordPress.pageBy.childPages.nodes.map(
                      (childPage, index) => {
                        return (
                          <div
                            key={`childpage-${index}-${i}`}
                            className="row row-4 mb-4 mb-md-5 md-lg-4"
                          >
                            <div className="col-md-5">
                              {childPage.featuredImage && (
                                <Card
                                  variant="boxless"
                                  img={
                                    childPage.featuredImage.sourceUrl ||
                                    data.imgCoverMain.childImageSharp.fluid
                                  }
                                  imgHeight="h-ratio-3-2"
                                  // link={`/sustainability/social/${childPage.slug}`}
                                  className="mb-md-0"
                                />
                              )}
                            </div>
                            <div className="col-md-7 d-flex align-items-center">
                              <HeadingBasic
                                h2={childPage?.title}
                                text={[childPage.excerpt]}
                                btnText="Learn More"
                                btnLink={`/sustainability/environment/${childPage.slug}`}
                                btnClassName="mt-n2"
                                className="mb-2 mw-100 mt-sm-down-3"
                              />
                            </div>
                          </div>
                        )
                      }
                    )}
                  </div>
                </section>
              )
            }
            return <div key={`empty-${i}`}></div>
          default:
            return <div key={`default-${i}`}>{item.name}</div>
        }
      })}
    </Layout>
  )
}

export default Environment
